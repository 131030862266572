<template>
  <div>
    <table>
      <thead>
        <tr>
          <th>Supplier</th>
          <th>Count</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="supplierx in supplierDetails" :key="supplierx.manufacturer_supplier">
          <td>{{ supplierx.manufacturer_supplier }}</td>
          <td>
            {{ supplierx.number_of_units_total }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import AmazonReportFilter from '@/http/models/search-filters/AmazonReportFilter'
import amazonReportService from '@/http/requests/reports/amazonReportService'

export default {
  props: {
    productList: {
      type: Array,
      default: () => [],
    },
    supplier: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      supplierDetails: [],
    }
  },
  watch: {
    productList: {
      immediate: true,
      deep: true,
      handler(currValue) {
        if (currValue && currValue.length > 0) {
          this.getSupplierData()
        }
      },
    },
  },
  methods: {
    async getSupplierData() {
      let filters = AmazonReportFilter.getFilters()
      filters = { ...filters, supplier: this.supplier, productList: this.productList }
      const response = await amazonReportService.getAmazonSupplierReport(filters)
      this.supplierDetails = response?.supplierList
    },
  },
}
</script>

<style lang="scss" scoped>
table {
  width: 100%;
  min-width: 250px;
  border-collapse: collapse;
}

thead {
  background-color: rgba(0, 0, 0, 0.1);
}

th {
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

tr:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.05);
}

td {
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.2);
}
</style>
